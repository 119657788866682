import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SharedModule } from 'primeng/api';

@Component({
  selector: 'cpm-confirmation-dialog',
  standalone: true,
    imports: [
        ButtonModule,
        ConfirmDialogModule,
        SharedModule
    ],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {}

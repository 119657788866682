<p-confirmDialog #cd>
  <ng-template pTemplate="headless" let-message>
    <div class="cpm-confirm-dialog-content">
      <h6>{{ message.header }}</h6>
      <p>{{ message.message }}</p>
      <div class="actions">
        <p-button [label]="message.rejectLabel" (click)="cd.reject()" size="small"></p-button>
        <p-button severity="danger" [outlined]="true" [label]="message.acceptLabel" (click)="cd.accept()" size="small"></p-button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
